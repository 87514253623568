<template>
  <div>
    <v-dialog
      persistent
      v-model="openDialogChangePass"
      width="590"
      style="z-index: 999999999999999999"
    >
      <v-card style="border-radius: 10px; padding: 20px !important">
        <v-icon @click="close" class="dp-icon-modal">mdi-close</v-icon>
        <v-container>
          <v-row>
            <v-col>
              <p class="dp-title-modal" style="text-align: center">
                Cambiar contraseña
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="nuevaContrasena"
                class="text-Poppins"
                label="Nueva contraseña*"
                autocomplete="new-password"
                style="margin-bottom: -10px"
                :append-icon="ncShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (ncShowPassword = !ncShowPassword)"
                :type="ncShowPassword ? 'text' : 'password'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="confirmarNuevaContrasena"
                class="text-Poppins"
                label="Confirmar nueva contraseña*"
                autocomplete="new-password"
                style="margin-bottom: -10px"
                :append-icon="cncShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (cncShowPassword = !cncShowPassword)"
                :type="cncShowPassword ? 'text' : 'password'"
              ></v-text-field>
            </v-col>
          </v-row>

          <br />
          <br />

          <v-row>
            <v-col cols="12" sm="12" class="text-center">
              <v-btn
                @click="close"
                x-large
                color="black"
                width="175"
                outlined
                style="
                  height: 40px;
                  font-size: 13px;
                  font-weight: 600;
                  border-radius: 5px;
                  margin-right: 15px;
                "
                >Cancelar
              </v-btn>
              <v-btn
                x-large
                width="175"
                color="primary"
                style="
                  height: 40px;
                  color: black;
                  box-shadow: 0px 20px 40px #00000029;
                  border-radius: 5px;
                  font-size: 13px;
                  font-weight: 600;
                  margin-left: 15px;
                "
                :disabled="!validateForm"
                @click="changePassword"
                :loading="loadingButtonGuardar"
                >Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DetailsRequests",
  props: {
    openDialogChangePass: {
      type: Boolean,
      default: false,
    },
    itemDetails: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      idUsuario: "",
      nuevaContrasena: "",
      confirmarNuevaContrasena: "",
      ncShowPassword: false,
      cncShowPassword: false,
      loadingButtonGuardar: false,
    };
  },
  computed: {
    validateForm() {
      return (
        this.nuevaContrasena !== "" && this.confirmarNuevaContrasena !== ""
      );
    },
  },
  methods: {
    close() {
      this.$emit("close");
      this.clearForm();
    },
    allData() {
      this.idUsuario = this.itemDetails.sUserId;
    },
    clearForm() {
      this.nuevaContrasena = "";
      this.confirmarNuevaContrasena = "";
    },
    changePassword() {
      this.loadingButtonGuardar = true;

      const payload = {
        sUserId: this.idUsuario,
        sPassword: this.nuevaContrasena,
        sConfirmPassword: this.confirmarNuevaContrasena,
      };

      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          contentType: "application/x-www-form-urlencoded;charset=utf-8",
        },
      };

      //post credentials and get access token from laravel backend
      db.put(`${uri}/api/v1/auth/password`, payload, config)
        .then((response) => {
          this.loadingButtonGuardar = false;
          const alert = {
            color: "green",
            text: response.data.message,
          };

          this.close();
          this.$store.commit("toggle_alert", alert);
          this.$store.commit("refresher", true);
        })
        .catch((err) => {
          this.bLoading = false;
          this.loadingButtonGuardar = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
  },
  watch: {
    openDialogChangePass: function () {
      if (this.openDialogChangePass) {
        this.allData();
      }
    },
  },
};
</script>